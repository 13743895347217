import {mapActions, mapMutations} from 'vuex';
import StorageLock from 'razlet-auth/src/utils/lock';
import {REFRESH, LOGOUT} from 'razlet-auth/src/store/actions.types';
import {GET_USER_AND_BALANCE} from 'razlet-sdk/lib/store/auth/actions.type';
import {USER_CLEAR, USER_SUCCESS, TOKEN_SUCCESS_NO_EVENT} from 'razlet-auth/src/store/mutations.types';
import PushMethods from 'razlet-sdk/lib/method/push/push';
import {oldDebounce as debounce} from 'razlet-sdk/lib/utils/helpers';

export default {
  data() {
    return {
      authChannel: null,
      pushSender: new PushMethods(),
      setToken: null,
    };
  },
  computed: {
    isAuthRequired() {
      return process.env.auth === 'required';
    },
    isAuthOptional() {
      return process.env.auth === 'optional';
    },
    isNoAuth() {
      return process.env.auth === 'none';
    },
    isClientSide() {
      return process.client;
    },
    firebaseToken() {
      return this.$store.state.pushToken;
    },
  },
  watch: {
    '$route': {
      async handler(route) {
        if (this.isClientSide && ((this.isAuthRequired && !this.isOffline) || (this.isAuthOptional && await this.$getIsLogged()))) {
          await this.checkAuth(route);
        }
      },
      immediate: true,
    },
  },
  created() {
    this.setToken = debounce(this.tokenSuccessNoEvent, 200);
  },
  async mounted() {
    this.$bus.$on('logout', () => {
      this.logout();
    });
    if (!this.isNoAuth) {
      this.authChannel = new BroadcastChannel('auth');
      this.authChannel.onmessage = (event) => {
        console.log('[WEB] broadcast message received', event.data);
        this.tokenSuccessNoEvent(event.data || null);
        if (event.data) {
          this.userSuccess(this.$jwt.decodeToken(event.data));
          this.postLogin();
        } else {
          this.userClear();
          this.postLogout();
        }
      };

      this.$bus.$on('token-set', async (data) => {
        const {new: newToken, old: oldToken} = data;
        console.log('[WEB] [APP] access token set');
        this.getUserInfo(this.$route);
        if (!oldToken) {
          this.registerPush(newToken);
        }
        else if (this.isUserChanged(oldToken, newToken)) {
          console.log('[WEB] [APP] user switched');
          this.switchUserPush(oldToken, newToken);
        }
        this.postToOtherTabs(newToken);
        await this.$setIsLogged(true);
      });

      this.$bus.$on('token-clear', async (oldToken) => {
        console.log('[WEB] [APP] access token cleared');
        this.unregisterPush(oldToken);
        this.postToOtherTabs('');
        await this.$setIsLogged(false);
      });
    }
  },
  async beforeDestroy() {
    this.$bus.$off('logout');

    this.$bus.$off('token-set');
    this.$bus.$off('token-clear');

    if (this.authChannel) this.authChannel.close();
  },
  methods: {
    ...mapActions('auth', [GET_USER_AND_BALANCE]),
    ...mapActions([REFRESH, LOGOUT]),
    ...mapMutations([USER_SUCCESS, TOKEN_SUCCESS_NO_EVENT, USER_CLEAR, 'setPushRegistered']),
    async postToOtherTabs(value) {
      if (this.authChannel) {
        const sl = new StorageLock('isPosting');
        await sl.lock();
        this.authChannel.postMessage(value);
        sl.unlock();
      }
    },
    isUserChanged(oldValue, newValue) {
      if (!oldValue) return true;

      const oldUser = this.$jwt.decodeToken(oldValue);
      const newUser = this.$jwt.decodeToken(newValue);

      return oldUser.id !== newUser.id || oldUser.contractId !== newUser.contractId;
    },
    getUserInfo(route) {
      this.getUserAndBalance(this.$store).then(response => {
        this.checkRole(route, response[1].data.roles);
        this.$bus.$emit('user-balance-loaded');
      });
    },
    checkRole(route, roles) {
      const {access} = route.meta;
      if (route.meta && access && access.length && roles && !access.some(v => roles.includes(v))) {
        this.goToBadAccess();
      }
    },
    // eslint-disable-next-line no-unused-vars
    registerPush(token) {
      // this.$bus.$emit('init-notifications', token);
    },
    // eslint-disable-next-line no-unused-vars
    switchUserPush(oldToken, newToken) {
      /* this.pushSender.unregister(oldToken, this.firebaseToken).then(() => {
        this.setPushRegistered(false);
        this.$bus.$emit('reinit-notifications', newToken);
      }); */
    },
    // eslint-disable-next-line no-unused-vars
    unregisterPush(token) {
      /* this.$bus.$emit('stop-notifications');
      console.log('[WEB] [APP] unregister token firebase', this.firebaseToken);
      return this.pushSender.unregister(token, this.firebaseToken).then(() => {
        this.setPushRegistered(false);
      }); */
    },
    async checkAuth(route) {
      if (!this.$store.getters.token && !this.$store.getters.loading) {
        const token = await this.$interceptor.intercept();
        if (this.isAuthRequired && !token && route.name !== 'login') this.goToLoginPage();
      } 
    },
    logout() {
      this.logoutAction().then(() => {
        this.postLogout();
      });
    },
    postLogin() {
      this.getUserInfo(this.$route);
      if (this.$route.name === 'login') this.$bus.$emit('redirect-from-login');
    },
    postSwitch() {
      this.getUserInfo(this.$route);
    },
    postLogout() {
      if (this.isAuthRequired && this.$route.name !== 'login') this.goToLoginPage();
    },
    goHome() {
      if (this.$route.path !== '/') this.$router.push(`/`);
    },
    goToBadAccess() {
      if (this.$route.name !== 'bad-access') this.$router.push(`/bad-access`);
    },
    goToLoginPage() {
      if (!this.isNoAuth) this.$router.push(`/login?path=${btoa(this.$route.fullPath)}`);
    },
  },
};